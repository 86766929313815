import axios from "axios";

const API_URL = process.env.BACKEND_URL + '/api';

// Fetch all webhooks
export const fetchWebhooks = () => axios.get(`${API_URL}/webhooks`);

// Create a new webhook
export const createWebhook = (signatureHeader, signatureKey, hashMethod) =>
  axios.post(`${API_URL}/webhooks`, { 
    signatureHeader, 
    signatureKey,
    hashMethod 
  });

// Fetch webhook by ID
export const fetchWebhookById = (id) =>
  axios.get(`${API_URL}/webhooks/${id}`);

// Fetch all requests for a specific webhook
export const fetchWebhookRequests = (id) =>
  axios.get(`${API_URL}/webhooks/${id}/requests`);

export const deleteWebhook = (webhookId) =>
  axios.delete(`${API_URL}/webhooks/${webhookId}`);