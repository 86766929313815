import React, { useEffect, useState } from "react";
import { fetchWebhooks, createWebhook } from "../api/webhooks";

const Home = () => {
  const [webhooks, setWebhooks] = useState([]);
  const [signatureHeader, setSignatureHeader] = useState("");
  const [signatureKey, setSignatureKey] = useState("");
  const [hashMethod, setHashMethod] = useState("sha256");

  useEffect(() => {
    loadWebhooks();
  }, []);

  const loadWebhooks = async () => {
    try {
      const response = await fetchWebhooks();
      setWebhooks(response.data);
    } catch (error) {
      console.error("Failed to fetch webhooks:", error);
    }
  };

  const handleCreateWebhook = async () => {
    try {
      await createWebhook(signatureHeader, signatureKey, hashMethod);
      loadWebhooks();
      setSignatureHeader("");
      setSignatureKey("");
    } catch (error) {
      console.error("Failed to create webhook:", error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <div className="flex flex-col md:flex-row gap-4">
        {/* Create Webhook Form Card */}
        <div className="md:w-1/3">
          <div className="card bg-secondary shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Create Webhook</h2>
              <div className="form-control gap-2">
                <input
                  type="text"
                  placeholder="Signature Header"
                  value={signatureHeader}
                  onChange={(e) => setSignatureHeader(e.target.value)}
                  className="input input-bordered input-sm w-full"
                />
                <input
                  type="text"
                  placeholder="Signature Key"
                  value={signatureKey}
                  onChange={(e) => setSignatureKey(e.target.value)}
                  className="input input-bordered input-sm w-full"
                />
                <select
                  value={hashMethod}
                  onChange={(e) => setHashMethod(e.target.value)}
                  className="select select-bordered select-sm w-full"
                >
                  <option value="sha256">SHA-256</option>
                  <option value="sha512">SHA-512</option>
                  <option value="sha1">SHA-1</option>
                  <option value="md5">MD5</option>
                </select>
                <button 
                  onClick={handleCreateWebhook} 
                  className="btn btn-primary btn-sm"
                >
                  Create Webhook
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Webhooks List Card */}
        <div className="md:w-2/3">
          <div className="card bg-base-200 shadow-xl">
            <div className="card-body">
              <h2 className="card-title">Webhooks</h2>
              <div className="overflow-y-auto max-h-[60vh]">
                <table className="table table-zebra table-sm">
                  <thead className="sticky top-0 bg-base-200">
                    <tr>
                      <th>URL</th>
                      <th>Hash</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {webhooks.map((webhook) => (
                      <tr key={webhook._id} className="hover">
                        <td>
                          <a 
                            href={`/webhook/${webhook._id}`}
                            className="link link-primary text-sm truncate block max-w-xs"
                          >
                            {`${webhook._id}`}
                          </a>
                        </td>
                        <td className="text-sm">
                          {webhook.hashMethod || 'sha256'}
                        </td>
                        <td className="text-sm">
                          {new Date(webhook.createdAt).toLocaleDateString()}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;