import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchWebhookById, fetchWebhookRequests, deleteWebhook } from '../api/webhooks';
import { AlertCircle, Key, Link as LinkIcon, Trash2, Copy, Hash, MenuIcon, X } from 'lucide-react';
import socket from '../socket';

const WebhookDetail = () => {
  const { webhookId } = useParams();
  const navigate = useNavigate();
  const [webhook, setWebhook] = useState(null);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [notification, setNotification] = useState(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [showHashCode, setShowHashCode] = useState(false);
  const requestListRef = useRef(null);

  useEffect(() => {
    if (webhookId) {
      // Join the webhook room
      socket.emit('joinWebhookRoom', webhookId);

      // Listen for new requests
      const handleNewRequest = (data) => {
        console.log('New request received:', data);
        setRequests(prev => [data.request, ...prev]);
        setNotification('New webhook request received');
        setTimeout(() => setNotification(null), 3000);
      };

      // Listen for successful room join
      const handleRoomJoined = (roomId) => {
        console.log('Joined room:', roomId);
      };

      socket.on('newRequest', handleNewRequest);
      socket.on('roomJoined', handleRoomJoined);
      socket.on('webhookDeleted', (deletedWebhookId) => {
        if (deletedWebhookId === webhookId) {
          navigate('/webhooks');
        }
      });

      return () => {
        socket.off('newRequest', handleNewRequest);
        socket.off('roomJoined', handleRoomJoined);
        socket.off('webhookDeleted');
      };
    }
  }, [webhookId, navigate]);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const [webhookResponse, requestsResponse] = await Promise.all([
        fetchWebhookById(webhookId),
        fetchWebhookRequests(webhookId, { page, limit: 50 })
      ]);
      setWebhook(webhookResponse.data);
      setRequests(prev => page === 1 ? requestsResponse.data.requests : [...prev, ...requestsResponse.data.requests]);
      setHasMore(requestsResponse.data.pagination.page < requestsResponse.data.pagination.pages);
    } catch (error) {
      console.error("Failed to fetch data:", error);
      setError("Failed to load webhook data");
    } finally {
      setLoading(false);
    }
  }, [webhookId, page]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight * 1.5 && !loading && hasMore) {
      setPage(prev => prev + 1);
    }
  };

  const getWebhookUrl = () => {
    const backendUrl = process.env.BACKEND_URL || 'http://localhost:5055';
    return `${backendUrl}/hook/${webhookId}`;
  };

  const copyToClipboard = async (text, message = 'Copied to clipboard!') => {
    try {
      await navigator.clipboard.writeText(text);
      setNotification(message);
      setTimeout(() => setNotification(null), 3000);
    } catch (err) {
      console.error('Failed to copy:', err);
      setNotification('Failed to copy to clipboard');
    }
  };

  if (loading && !requests.length) {
    return (
      <div className="container mx-auto p-4 flex justify-center items-center min-h-screen">
        <span className="loading loading-spinner loading-lg"></span>
      </div>
    );
  }

  if (error) {
    return (
      <div className="container mx-auto p-4">
        <div className="alert alert-error">
          <AlertCircle className="w-6 h-6" />
          <span>{error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container mx-auto p-4">
      {notification && (
        <div className="toast toast-top toast-center z-50">
          <div className="alert alert-success">
            <span>{notification}</span>
          </div>
        </div>
      )}

      <button
        className="lg:hidden fixed bottom-4 right-4 btn btn-primary btn-circle z-30"
        onClick={() => setSidebarOpen(!sidebarOpen)}
      >
        {sidebarOpen ? <X className="w-6 h-6" /> : <MenuIcon className="w-6 h-6" />}
      </button>

      <div className="flex flex-col lg:flex-row gap-4 relative">
        <div className={`lg:w-1/4 w-full fixed lg:relative inset-0 z-20 lg:z-0 transform ${sidebarOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0'
          } transition-transform duration-200 ease-in-out h-[calc(100vh-72px-3rem)]`}>
          <div className="card bg-base-200 shadow-xl h-full">
            <div className="card-body p-4 flex flex-col h-full">
              <div className="flex justify-between items-center bg-base-200 py-2">
                <h2 className="card-title">
                  Incoming Requests
                  <div className="badge badge-primary">{requests.length}</div>
                </h2>
                <button
                  className="lg:hidden btn btn-ghost btn-sm"
                  onClick={() => setSidebarOpen(false)}
                >
                  <X className="w-4 h-4" />
                </button>
              </div>
              <div
                className="overflow-y-auto flex-grow mt-2"
                ref={requestListRef}
                onScroll={handleScroll}
              >
                <ul className="menu p-0 [&_li>*]:rounded-lg">
                  {requests.map((request) => (
                    <li key={request._id}>
                      <button
                        onClick={() => {
                          setSelectedRequest(request);
                          setSidebarOpen(false);
                        }}
                        className={`w-full text-left hover:bg-base-300 ${selectedRequest && selectedRequest._id === request._id
                          ? "bg-primary text-primary-content"
                          : "xs"
                          }`}
                      >
                        <div className="flex flex-col">
                          <span className="font-medium">
                            {new Date(request.receivedAt).toLocaleTimeString()}
                          </span>
                          <span className="text-xs opacity-70">
                            {new Date(request.receivedAt).toLocaleDateString()}
                          </span>
                          <div className="flex items-center mt-1">
                            <div className={`badge badge-sm ${request.signatureValid ? 'badge-success' : 'badge-error'}`}>
                              {request.signatureValid ? 'Valid' : 'Invalid'} Signature
                            </div>
                          </div>
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
                {loading && (
                  <div className="flex justify-center p-4">
                    <span className="loading loading-spinner"></span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="lg:w-3/4 w-full h-full overflow-hidden">
          <div className="card bg-base-200 shadow-xl h-full">
            <div className="card-body h-full flex flex-col">
              {webhook && (
                <>
                  <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
                    <h2 className="card-title">Webhook Details</h2>
                    <div className="flex flex-wrap items-center gap-2">
                      <div className="badge badge-info text-base-content gap-2">
                        <AlertCircle className="w-4 h-4" />
                        Signature Required
                      </div>
                      <div
                        className="badge badge-secondary gap-2 cursor-pointer"
                        onMouseEnter={() => setShowHashCode(true)}
                        onMouseLeave={() => setShowHashCode(false)}
                      >
                        <Hash className="w-4 h-4" />
                        {webhook.hashMethod}
                      </div>
                      <button
                        className="btn btn-error btn-sm whitespace-nowrap"
                        onClick={async () => {
                          if (window.confirm('Are you sure you want to delete this webhook?')) {
                            try {
                              await deleteWebhook(webhookId);
                              navigate('/webhooks');
                            } catch (error) {
                              setNotification('Failed to delete webhook');
                            }
                          }
                        }}
                      >
                        <Trash2 className="w-4 h-4" /> Delete
                      </button>
                    </div>
                  </div>

                  <div className="card bg-accent text-primary-content">
                    <div className="card-body p-2 text-sm">
                      <div className="grid gap-2">
                        <div className="grid grid-cols-[16px,auto,1fr,32px] items-center gap-2">
                          <LinkIcon className="w-3 h-3" />
                          <span className="font-medium whitespace-nowrap">URL:</span>
                          <code className="bg-primary-focus px-1.5 py-0.5 rounded text-xs overflow-x-auto">
                            {getWebhookUrl()}
                          </code>
                          <button
                            className="btn btn-ghost btn-xs p-0"
                            onClick={() => copyToClipboard(getWebhookUrl())}
                          >
                            <Copy className="w-3 h-3" />
                          </button>
                        </div>
                        <div className="grid grid-cols-[16px,auto,1fr,32px] items-center gap-2">
                          <Key className="w-3 h-3" />
                          <span className="font-medium whitespace-nowrap">Key:</span>
                          <code className="bg-primary-focus px-1.5 py-0.5 rounded text-xs overflow-x-auto">
                            {webhook.signatureKey}
                          </code>
                          <button
                            className="btn btn-ghost btn-xs p-0"
                            onClick={() => copyToClipboard(webhook.signatureKey)}
                          >
                            <Copy className="w-3 h-3" />
                          </button>
                        </div>
                        <div className="grid grid-cols-[16px,auto,1fr,32px] items-center gap-2">
                          <AlertCircle className="w-3 h-3" />
                          <span className="font-medium whitespace-nowrap">Header:</span>
                          <code className="bg-primary-focus px-1.5 py-0.5 rounded text-xs overflow-x-auto">
                            {webhook.signatureHeader}
                          </code>
                          <button
                            className="btn btn-ghost btn-xs p-0"
                            onClick={() => copyToClipboard(webhook.signatureHeader)}
                          >
                            <Copy className="w-3 h-3" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedRequest ? (
                    <div className="overflow-y-auto flex-grow mt-4 space-y-4">
                      <div className="flex items-center gap-2">
                        <h3 className="text-lg font-semibold">Signature</h3>
                        <div className={`badge ${selectedRequest.signatureValid ? 'badge-success' : 'badge-error'}`}>
                          {selectedRequest.signatureValid ? 'Valid' : 'Invalid'} Signature
                        </div>
                      </div>
                      <div className="mockup-code overflow-x-auto">
                        {showHashCode ? (
                          <>
                            <pre data-prefix=">" className="text-sm font-semibold text-accent">
                              // Compute signature
                            </pre>
                            <pre data-prefix=">" className="text-sm">
                              const hmac = crypto.createHmac('{webhook.hashMethod}', '{webhook.signatureKey}');
                            </pre>
                            <pre data-prefix=">" className="text-sm">
                              const computedHash = hmac.update(payload).digest('hex');
                            </pre>
                          </>
                        ) : (
                          <>
                            <pre data-prefix=">" className="text-sm font-semibold text-accent">
                              Signature Comparison:
                            </pre>
                            <pre data-prefix=">" className="text-sm">
                              Computed Hash: {selectedRequest.computedSignatureHash || 'No computed hash available'}
                            </pre>
                            <pre data-prefix=">" className="text-sm">
                              Received Hash: {selectedRequest.receivedSignatureHash || 'No received hash available'}
                            </pre>
                          </>
                        )}
                      </div>
                      <h3 className="text-lg font-semibold mt-4">Headers</h3>
                      <div className="mockup-code overflow-x-auto">
                        {Object.entries(selectedRequest.headers).map(([key, value]) => (
                          <pre data-prefix=">" key={key} className="text-sm">
                            <code>{`${key}: ${value}`}</code>
                          </pre>
                        ))}
                      </div>

                      <h3 className="text-lg font-semibold mt-4">Raw Body</h3>
                      <div className="mockup-code overflow-x-auto">
                        <pre>
                          <code>{JSON.stringify(selectedRequest.body, null, 2)}</code>
                        </pre>
                      </div>
                      <h3 className="text-lg font-semibold mt-4">Body</h3>
                      <div className="mockup-code overflow-x-auto">
                        {JSON.stringify(selectedRequest.body, null, 2)
                          .split("\n")
                          .map((line, index) => (
                            <pre data-prefix=">" key={index} className="text-sm">
                              <code>{line}</code>
                            </pre>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <div className="flex-grow flex flex-col items-center justify-center text-base-content/60 py-12">
                      <MenuIcon className="w-16 h-16 mb-4" />
                      <p className="text-xl font-medium">Select a request to view details</p>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebhookDetail;