import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
    return (
      <div className="navbar bg-neutral text-neutral-content shadow-md">
        <div className="flex-1">
          <Link to="/" className="btn btn-ghost normal-case text-xl">
            <span>🌐 Webhook Manager</span>
          </Link>
        </div>
        <div className="flex-none">
          <ul className="menu menu-horizontal px-1">
            <li>
              <Link to="/" className="hover:bg-neutral-content-focus">Home</Link>
            </li>
          </ul>
        </div>
      </div>
    );
  };
  

export default Navbar;
