import { io } from 'socket.io-client';

const BACKEND_URL = process.env.BACKEND_URL || 'http://localhost:5055';

console.log('Initializing socket with backend URL:', BACKEND_URL);

const socket = io(BACKEND_URL, {
  transports: ['websocket', 'polling'],
  autoConnect: true,
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 1000,
  timeout: 20000,
  withCredentials: true,
  path: '/socket.io/',
  secure: true
});

// Enhanced error logging
socket.on('connect_error', (error) => {
  console.error('Socket connection error:', error.message, {
    error,
    url: BACKEND_URL,
    transport: socket.io?.engine?.transport?.name,
    state: {
      connected: socket.connected,
      disconnected: socket.disconnected,
    }
  });
});

socket.on('connect', () => {
  console.log('Socket connected successfully:', {
    id: socket.id,
    url: BACKEND_URL,
    transport: socket.io?.engine?.transport?.name
  });
});

socket.on('disconnect', (reason) => {
  console.log('Socket disconnected:', {
    reason,
    url: BACKEND_URL,
    wasConnected: socket.connected,
    id: socket.id
  });
});

export default socket;